@import './src/assets/styles/mixins.scss';
@import './src/assets/styles/variables.scss';

.tableWrapper {
  position: relative;
  height: calc(100vh - 30px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  .headerWrapper {
    height: 40px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    & > h2 {
      min-width: max-content;
      margin-right: 6px;
    }
    .navTabWrapper {
      justify-self: flex-start;
    }
    .filter {
      display: flex;
      justify-self: end;
    }
    .additionalInfoBlock {
      display: flex;
      gap: 2px;
      white-space: nowrap;
      max-height: 20px;
      width: 100%;
      overflow: hidden;
    }
    .addInfoSmaller {
      font-size: 12px;
    }
  }
}

.itemPiece {
  width: max-content;
  height: max-content;
  padding: 7px 18px;
  margin: 2px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.infoContainer {
  @include flex(column, space-between, flex-start, nowrap);
  margin: 0 0 20px 0;
  .turnoverBlock {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    .turnover {
      @extend .itemPiece;
      background-color: #d9e6ff;
      p {
        font-weight: 700;
      }
    }
  }
  .infoBlock {
    @include flex(row, space-between, center, nowrap);
    padding-left: 250px;
    width: 100%;
    .info {
      @extend .itemPiece;
      background-color: #d9e6ff;
    }
  }
  .infoBlockFull {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    .info {
      @extend .itemPiece;
      background-color: #b5f6ff;
    }
  }
}

.mainTitle {
  font-size: 16px;
  font-weight: 500;
  color: $darkGrey;
}

.tableContainer {
  height: auto;
}

.tableContainerFull {
  height: calc(100vh - 200px);
  @media screen and (min-width: 976px) {
    height: calc(100vh - 180px);
  }
}

.monthsContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 1rem;
    padding: 8px 2px;
    @media (max-height: 850px) {
      font-size: 0.85rem;
      padding: 4px 2px;
    }
  }

  .tBodyCellClass {
    font-size: 1.1rem;
    padding: 8px 4px;
    @media (max-height: 850px) {
      font-size: 0.9rem;
      padding: 4px 2px;
    }
  }

  .btnRoot {
    line-height: 1;
    padding: 6px;
  }
}

$mo_stats_low: #ec1818;
$mo_stats_mid_low: #f67e46;
$mo_stats_mid: #e8ca5e;
$mo_stats_mid_high: #458a47;
$mo_stats_high: #24ee36;

.low {
  color: $mo_stats_low;
}

.middleLow {
  color: $mo_stats_mid_low;
}
.middle {
  color: $mo_stats_mid;
}
.middleHigh {
  color: $mo_stats_mid_high;
}
.high {
  color: $mo_stats_high;
}

.coloredSquare {
  width: 16px;
  height: 16px;
}

.currentColorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 90px;
  .low {
    @extend .coloredSquare;
    background-color: $mo_stats_low;
  }
  .lowMid {
    @extend .coloredSquare;
    background-color: $mo_stats_mid_low;
  }
  .mid {
    @extend .coloredSquare;
    background-color: $mo_stats_mid;
  }
  .midHigh {
    @extend .coloredSquare;
    background-color: $mo_stats_mid_high;
  }
  .high {
    @extend .coloredSquare;
    background-color: $mo_stats_high;
  }
}
