@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.titleBlock {
  width: 100%;
  margin-bottom: 20px;

  @include flex(row, space-between, center, nowrap);

  .sectionTitle {
    font-size: 22px;
  }

  .editBtn {
    @include btnReset();

    font-size: 20px;
    padding: 12px 0 12px 20px;
    color: $blue;

    &:hover {
      text-decoration: underline;
    }
    &:disabled {
      color: $grey;
      text-decoration: none;
      cursor: auto;
    }
  }

  .disabledButton {
    opacity: 0.5;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}
