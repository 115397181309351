@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.btnBlockWrapper {
  max-width: 550px;
  margin: 0 auto;
}

.content {
  @include flex(row, center, flex-start, nowrap);

  margin-bottom: 50px;

  .profileCol {
    width: 50%;
    padding-right: 27.5px;

    .profileContent {
      margin-bottom: 20px;
    }
  }
  .singleCases {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .scCheck {
      transform: scale(1);
      padding: 0;
    }
  }
  .divider {
    margin: 10px 0px;
  }

  .orderCol {
    width: 50%;
    padding-left: 27.5px;

    .ordersSubBlock {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }

    .ordersSubtitle {
      font-size: 18px;
      font-weight: 700;
      text-decoration: underline;
      margin-bottom: 16px;
    }
  }
}

.modalContent {
  overflow: hidden;
  max-height: 90%;
  padding: 50px 30px 50px 50px;
}

.modalContentInner {
  padding-right: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }

  .customInputClass {
    margin-bottom: 25px;

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
}

.error {
  position: absolute;
  font-size: 20px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
